<template>
  <div class="w-full p-4">
    <div class="max-w-5xl mx-auto min-h-screen">
      <div class="text-left col-span-4 bg-white shadow-md rounded-md">
        <div class="flex flex-col justify-center items-center">
          <div
            class="w-12 h-12 rounded-full overflow-hidden border border-blue-400 flex items-center justify-center mt-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-8 w-8 text-brand"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </div>
          <div class="text-2xl">
            {{ $store.state.auth.user.name ? $store.state.auth.user.name : "" }}
          </div>

          <div>
            <router-link
              :to="{
                name: 'profileEdit',
                params: { id: user ? user.id : '0' },
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            </router-link>
          </div>
        </div>
        <hr class="border border-hoverColor" />
        <div class="p-2 flex flex-col items-center">
          <div v-if="user.designation" class="text-xl">
            Designation : <span v-html="user.designation"></span>
          </div>
          <div v-if="user.phone" class="text-xl">Phone : {{ user.phone }}</div>
          <div v-if="user.email" class="text-xl">Email : {{ user.email }}</div>
          <div v-if="user.gender" class="text-xl">
            Gender :
            {{ user.gender == 1 ? "Male" : user.gender == 2 ? "Female" : "" }}
          </div>
          <div v-if="user.bmdc" class="text-xl">BMDC : {{ user.bmdc }}</div>
          <div v-if="user.medical" class="text-xl">
            Medical : {{ user.medical }}
          </div>
          <div class="text-xl">
            Total Appointment :
            {{
              profile.last_appointment
                ? profile.last_appointment.data.length > 1
                  ? profile.last_appointment.data.length + " Times"
                  : profile.last_appointment.data.length + "Time"
                : ""
            }}
          </div>
        </div>
      </div>
      <div class="modal-body relative">
        <div class="grid grid-cols-12 gap-2">
          <div
            class="col-span-full md:col-span-4 mt-2 rounded-lg"
            v-for="(appointment, index) in appointments.data"
            :key="index"
          >
            <div class="bg-white shadow-md rounded-md p-2">
              <div
                class="text-left flex items-center gap-2 p-2"
                v-if="appointment.schedule"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-brand"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                <div>
                  <span class="text-md font-semibold">
                    {{
                      appointment.schedule
                        ? appointment.schedule.chamber.name
                        : ""
                    }}</span
                  >(
                  <span>{{
                    appointment.schedule
                      ? appointment.schedule.chamber.address
                      : ""
                  }}</span
                  >)
                </div>
              </div>
              <div
                class="text-left flex items-center gap-2 p-2"
                v-if="appointment.schedule"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-brand"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
                <span class="text-md font-semibold">{{
                  formatDate(appointment.schedule.date)
                }}</span>
              </div>
              <div
                class="flex items-center gap-2 p-2 text-center"
                v-if="appointment.schedule"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-brand"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span class="text-md font-semibold"
                  >{{ appointment.schedule.time_schedule.start_time }} -
                  {{ appointment.schedule.time_schedule.end_time }}</span
                >
              </div>
              <div
                class="flex items-center gap-2 p-2 text-center"
                v-if="appointment.schedule"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-brand"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
                <span class="text-md font-semibold">{{
                  profile.types[appointment.type]
                }}</span>
              </div>
              <div
                class="flex flex-col justify-center items-center"
                v-if="appointment.schedule"
              >
                <div
                  v-if="
                    appointment.assign_mentor &&
                    !appointment.mentor &&
                    appointment.schedule.date > now
                  "
                >
                  <!-- {{
                    appointment.requested_mentor
                      ? appointment.requested_mentor.id
                      : ""
                  }} -->
                  <div
                    class="text-brand text-sm text-left mb-2 border rounded-md p-2"
                  >
                    {{ message }} your mentor
                    <span class="font-bold">{{
                      appointment.assign_mentor
                        ? appointment.assign_mentor.user.name
                        : ""
                    }}</span>
                    please be patient
                  </div>
                </div>
                <div
                  class="border p-2 rounded-md"
                  v-html="
                    appointment.mentor
                      ? `<strong class='text-green-500'>Completed</strong>`
                      : appointment.schedule.date < now
                      ? `<strong class='text-red-500'>Missed</strong>`
                      : `<strong class='text-yellow-500'>Pending</strong>`
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal --->
    <!-- <div v-if="showModal" class="fixed top-20 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable relative md:mx-auto md:max-w-5xl pointer-events-none">
                <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                    <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalCenteredScrollableLabel">
                    Appointment Details
                    </h5>
                    <button class="text-3xl text-red-500" v-on:click="toggleModal()"> × </button>
                </div>
                <div class="modal-body relative p-4">
                    <div class="grid grid-cols-12 gap-4 overflow-auto">
                        <div class="col-span-full md:col-span-4 p-2" v-for="(appointment, index) in appointments.data" :key="index">
                            <div class="bg-gray-100 shadow-md">
                                <div class="">Name : {{ appointment.schedule.chamber ? appointment.schedule.chamber.name :'' }}</div> 
                                <div>Address : {{ appointment.schedule.chamber ? appointment.schedule.chamber.address :'' }}</div> 
                                <div>Date :{{ appointment.schedule.date }}</div>
                                <div>Time : {{ appointment.schedule.time_schedule.start_time }} -  {{ appointment.schedule.time_schedule.end_time }}</div>
                                <div v-html="appointment.mentor ? `<strong class='text-green-500'>Completed</strong>`: appointment.schedule.date < formatDate(new Date()) ? `<strong class='text-red-500'>Missed</strong>`:`<strong class='text-yellow-500'>Pending</strong>`"></div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div> -->
  </div>
</template>
<script>
import { now } from "lodash";

export default {
  data() {
    return {
      // open:false
      seen: false,
      showModal: false,
    };
  },
  created() {
    this.$store.dispatch("profile/getProfile");
  },
  computed: {
    profileId() {
      return this.$store.state.auth.user.id;
    },
    profile() {
      return this.$store.state.profile.profile;
    },
    user() {
      return this.$store.state.profile.profile.user || "";
    },
    appointments() {
      return this.profile.last_appointment || "";
    },
    message() {
      return this.profile.message || "";
    },
    now() {
      let date = new Date();
      let month = "" + (date.getMonth() + 1);
      let day = "" + date.getDate();
      let year = date.getFullYear();

      if (month.length < 2) {
        month = "0" + month;
      }

      if (day.length < 2) {
        day = "0" + day;
      }

      return [year, month, day].join("-");
    },
  },
  methods: {
    formatDate(input) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let date = new Date(input);
      let month = "" + months[date.getMonth()];
      let day = "" + date.getDate();
      let year = date.getFullYear();

      if (month.length < 2) {
        month = "0" + month;
      }

      if (day.length < 2) {
        day = "0" + day;
      }

      // July 31, 2022

      return [month, [day, year]].join(" ");
      // return [day, month, year].join("-");
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
    },
  },
};
</script>
